/* Общие стили для разделителя текста */
.text-divider {
    margin: 1em 0;
    line-height: 0;
    text-align: center;
  }
  
  .text-divider span {
    padding: 0.5em;
  }
  
  /* Светлая тема */
  .light .text-divider span {
    background-color: #ffffff; /* Белый фон, как у основного фона в светлой теме */
    color: #000000; /* Черный текст */
  }
  
  .light .text-divider:before {
    content: " ";
    display: block;
    border-top: 1px solid #e3e3e3; /* Светлая граница */
    border-bottom: 1px solid #f7f7f7; /* Светлая граница */
  }
  
  /* Темная тема */
  .dark .text-divider span {
    background-color: #1d1d1d; /* Темный фон, как у основного фона в темной теме */
    color: #ffffff; /* Белый текст */
  }
  
  .dark .text-divider:before {
    content: " ";
    display: block;
    border-top: 1px solid #444; /* Темная граница */
    border-bottom: 1px solid #555; /* Темная граница */
  }