/* Flashcard.css */

/* Общие стили для контейнера карточек */
.flashcard-container {
  perspective: 1000px;
}

/* Стили для карточки */
.flashcard {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}

.flashcard.flipped {
  transform: rotateY(180deg);
}

/* Стили для лицевой и обратной сторон карточки */
.flashcard-front,
.flashcard-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 80vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  transition: background-color 0.3s ease, color 0.3s ease; /* Плавный переход для темы */
}

/* Светлая тема */
.light .flashcard-front,
.light .flashcard-back {
  background-color: #f8f9fa; /* Светлый фон */
  color: #333; /* Темный текст */
}

/* Темная тема */
.dark .flashcard-front,
.dark .flashcard-back {
  background-color: #444; /* Темный фон */
  color: #f8f9fa; /* Светлый текст */
}

.flashcard-back {
  transform: rotateY(180deg);
}

/* Стили для страницы карточек */
.flashcards-page {
  padding: 20px;
}

.flashcard-wrapper {
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 0 auto;
}

.flashcard-front,
.flashcard-back {
  font-size: 1.5rem;
  text-align: center;
}

/* Стили для страницы тренировки */
.suggestive-training-page {
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Плавный переход для темы */
}

/* Светлая тема */
.light .suggestive-training-page {
  background-color: #ffffff;
  color: #333;
}

/* Темная тема */
.dark .suggestive-training-page {
  background-color: #1d1d1d;
  color: #ffffff;
}

.suggestive-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: none;
  padding: 0;
  transition: background-color 0.3s ease; /* Плавный переход для фона */
}

/* Светлая тема */
.light .suggestive-card-container {
  background-color: #f8f9fa; /* Светлый фон */
}

/* Темная тема */
.dark .suggestive-card-container {
  background-color: #444; /* Темный фон */
}

.suggestive-text-container {
  text-align: center;
  position: absolute;
  width: 80%;
  max-width: 80%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: top 0.3s ease, left 0.3s ease; /* Плавное перемещение */
}

.suggestive-text {
  margin-bottom: 10px;
  max-width: 100%;
  word-wrap: break-word;
  font-size: 2rem;
  line-height: 1.2;
  transition: color 0.3s ease; /* Плавный переход для цвета текста */
}

/* Светлая тема */
.light .suggestive-text {
  color: #333; /* Темный текст */
}

/* Темная тема */
.dark .suggestive-text {
  color: #f8f9fa; /* Светлый текст */
}

.suggestive-translation {
  max-width: 100%;
  word-wrap: break-word;
  font-size: 1.5rem;
  line-height: 1.2;
  transition: color 0.3s ease; /* Плавный переход для цвета текста */
}

/* Светлая тема */
.light .suggestive-translation {
  color: #666; /* Серый текст */
}

/* Темная тема */
.dark .suggestive-translation {
  color: #ddd; /* Светлый текст */
}

/* Анимация появления и исчезновения текста */
.fade-text-enter {
  opacity: 0;
  transform: scale(0.8);
}

.fade-text-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fade-text-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-text-exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

/* Стили для элементов управления */
.form-control,
.form-check-input,
.form-check-label {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Светлая тема */
.light .form-control {
  background-color: #ffffff; /* Белый фон */
  color: #333; /* Темный текст */
  border-color: #ccc; /* Светлая граница */
}

/* Темная тема */
.dark .form-control {
  background-color: #555; /* Темный фон */
  color: #f8f9fa; /* Светлый текст */
  border-color: #777; /* Темная граница */
}

/* Светлая тема */
.light .form-check-input {
  background-color: #ffffff; /* Белый фон */
  border-color: #ccc; /* Светлая граница */
}

/* Темная тема */
.dark .form-check-input {
  background-color: #555; /* Темный фон */
  border-color: #777; /* Темная граница */
}

/* Светлая тема */
.light .form-check-label {
  color: #333; /* Темный текст */
}

/* Темная тема */
.dark .form-check-label {
  color: #f8f9fa; /* Светлый текст */
}

/* Стили для модального окна */
.custom-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.custom-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease; /* Плавный переход для темы */
}

/* Светлая тема */
.light .custom-modal {
  background-color: #ffffff; /* Белый фон */
  color: #333; /* Темный текст */
}

/* Темная тема */
.dark .custom-modal {
  background-color: #1d1d1d; /* Темный фон */
  color: #f8f9fa; /* Светлый текст */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-footer .btn {
  margin-left: 10px;
}

/* Вертикальное расположение элементов */
.modal-body .d-flex.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body .form-label {
  margin-bottom: 5px;
}

.modal-body .form-control {
  width: 100%;
}