/* Глобальные CSS-переменные */
:root {
  --primary-color: #ff8c42; /* Оранжевый по умолчанию */
  --background-color: #ffffff; /* Светлый фон по умолчанию */
  --text-color: #000000; /* Темный текст по умолчанию */
  --border-color: rgba(0, 0, 0, 0.1); /* Граница для светлой темы */
}

/* Темная тема */
body.dark {
  --background-color: #121212; /* Темный фон */
  --text-color: #e0e0e0; /* Светлый текст */
  --border-color: rgba(255, 255, 255, 0.1); /* Граница для темной темы */
}

/* Общие стили для всех компонентов */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Стили для кнопок */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.btn-primary:hover {
  background-color: #e67339; /* Более темный оранжевый */
  border-color: #e67339;
}

.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

/* Стили для форм */
.form-control {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--primary-color);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.25rem rgba(255, 140, 66, 0.25); /* Оранжевый */
}

/* Стили для карточек */
.card {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

/* Стили для модальных окон */
.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Стили для навбара */
.navbar {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Стили для выпадающих списков */
.form-select {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--primary-color);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.form-select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.25rem rgba(255, 140, 66, 0.25); /* Оранжевый */
}

/* Стили для переключателя темы */
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Стили для уведомлений (toast) */
.Toastify__toast {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
}

.Toastify__toast-body {
  color: var(--text-color);
}

.Toastify__close-button {
  color: var(--text-color);
}

/* Стили для ссылок */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #e67339; /* Более темный оранжевый */
}

/* Стили для заголовков */
h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
}

/* Стили для иконок */
.bi {
  fill: var(--primary-color);
  transition: fill 0.3s ease;
}

.bi:hover {
  fill: #e67339; /* Более темный оранжевый */
}

/* Стили для списков с одинаковыми границами в светлой и темной теме */
.list-group {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color); /* Граница для списка */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.list-group-item {
  background-color: var(--background-color);
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color); /* Граница между элементами списка */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.list-group-item:last-child {
  border-bottom: none; /* Убираем границу у последнего элемента */
}

.list-group-item:hover {
  background-color: rgba(255, 255, 255, 0.05); /* Легкий оттенок при наведении */
}

/* Стили для аккордеона */
.accordion {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color); /* Граница для аккордеона */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.accordion-item {
  background-color: var(--background-color);
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color); /* Граница между элементами аккордеона */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.accordion-item:last-child {
  border-bottom: none; /* Убираем границу у последнего элемента */
}

.accordion-button {
  background-color: var(--background-color);
  color: var(--text-color);
  border: none; /* Убираем границу у кнопки */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(255, 255, 255, 0.05); /* Легкий оттенок для активного элемента */
  color: var(--text-color);
}

.accordion-button:focus {
  box-shadow: none; /* Убираем тень при фокусе */
}

.accordion-body {
  background-color: var(--background-color);
  color: var(--text-color);
  border: none; /* Убираем границу у тела аккордеона */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ql-editor img {
  max-width: 100%; /* Ограничивает ширину изображения до ширины контейнера */
  height: auto; /* Сохраняет пропорции изображения */
}