/* components/Logo.css */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px; /* Максимальная ширина изображения */
    margin: 0 auto;
  }
  
  .logo-image {
    width: 100%;
    height: auto;
    object-fit: contain; /* Поддерживает пропорции изображения */
  }