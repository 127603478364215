/* Общие стили для карусели */
.carousel-container {
  height: 100%;
}

.carousel-item {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Стили для панелей */
.panel {
  padding: 0px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px; /* Фиксированная высота для панелей */
  margin: 0 10px; /* Добавляем отступы между элементами */
}

/* Светлая тема */
.light .panel {
  background-color: #ffffff;
  color: #000000;
  border-color: #ddd;
}

.light .panel:hover {
  background-color: #f8f9fa;
}

.light .panel-heading {
  background-color: #f8f9fa;
  color: #000000;
  border-bottom: 1px solid #ddd;
}

.light .panel-heading h5 {
  color: #000000;
}

.light .panel-body {
  color: #000000;
}

/* Темная тема */
.dark .panel {
  background-color: #343a40;
  color: #ffffff;
  border-color: #444;
}

.dark .panel:hover {
  background-color: #495057;
}

.dark .panel-heading {
  background-color: #212529;
  color: #ffffff;
  border-bottom: 1px solid #444;
}

.dark .panel-heading h5 {
  color: #ffffff;
}

.dark .panel-body {
  color: #ffffff;
}

/* Общие стили для заголовка панели */
.panel-heading {
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.panel-heading h5 {
  margin: 0;
  font-size: 1.25rem;
}

/* Общие стили для тела панели */
.panel-body {
  flex: 1;
  padding: 10px;
  overflow: hidden;
}

.panel-body p {
  margin: 0;
}